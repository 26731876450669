<template>
  <div class="card card-custom">
    <div class="card-header">
    <div class="card-title d-flex align-items-center">
      <span class="card-icon">
        <i class="flaticon2-chat-1 text-primary "></i>
      </span>
      <h3 class="card-label ml-2 font-poppins">All Topping Groups</h3>
    </div>
    <v-row class="align-items-center justify-content-end no-gutters">
      <v-col cols="12" md="2" v-if="isAnyFilterApplied" class="px-2 mb-2 d-flex justify-end clear-filters">
        <span class="cursor-pointer text-primary" @click="clearFilters">Clear Filters</span>
      </v-col>
      <v-col cols="12" md="3" class="px-2 mb-2">
        <v-text-field
          v-model="businessName"
          outlined
          dense
          hide-details
          placeholder="Filter by Business Name"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="1" class="px-2 mb-2 d-flex justify-end">
        <v-btn elevation="2" class="bg-primary text-light font-poppins"  @click="loadToppingGroups">Search</v-btn>
      </v-col>
    </v-row>
  </div>
    <div class="card-body p-0">
      <div class="row my-5 px-2">
        <div class="col-sm-12">
          <v-data-table
            :headers="headers"
            :items="groups"
            :server-items-length="totalGroups"
            :loading="loading"
            class="elevation-1"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="edit(item.id)">
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>

          <v-pagination
            v-model="currentPage"
            :length="pages"
            :total-visible="7"
            @input="loadToppingGroups"
          ></v-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "AllToppingGroups",
  data() {
    return {
      headers: [
        { text: "Store Name", align: "left", sortable: false, value: "business.store_name" },
        { text: "Business Name", align: "left", sortable: false, value: "business.name" },
        { text: "Name", align: "left", sortable: false, value: "name" },
        { text: "Printing Name", align: "left", sortable: false, value: "printing_name" },
        { text: "Required", align: "left", sortable: false, value: "required" },
        { text: "Min. Required", align: "left", sortable: false, value: "required_count" },
      ],
      groups: [],
      totalGroups: 0,
      currentPage: 1,
      loading: false,
      businessName: "",
      itemsPerPage: 5,
    };
  },
  computed: {
    pages() {
      return Math.ceil(this.totalGroups / this.itemsPerPage);
    },
    isAnyFilterApplied() {
      return this.businessName !== "";
    },
  },
  watch: {
    currentPage(newPage) {
      this.loadToppingGroups(newPage);
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Menu Topping Groups" }]);
    this.loadToppingGroups();
  },
  methods: {
    loadToppingGroups(page = 1) {
      this.loading = true;
      ApiService.post("admin/topping-groups", {
        business_name: this.businessName,
        per_page: this.itemsPerPage,
        page: page,
      })
        .then(({ data }) => {
          this.groups = data.data;
          this.totalGroups = data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    edit(id) {
      // Handle edit functionality
    },
    clearFilters() {
      this.businessName = "";
      this.loadToppingGroups();
    },
  },
};
</script>

<style scoped>
/* Add your scoped styles here if needed */
</style>
